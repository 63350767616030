define("discourse/plugins/discourse-automation/discourse/controllers/admin-plugins-discourse-automation-new", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let AutomationNew = _exports.default = (_class = class AutomationNew extends _controller.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "form", null);
      _defineProperty(this, "error", null);
    }
    init() {
      super.init(...arguments);
      this._resetForm();
    }
    saveAutomation(automation) {
      this.set("error", null);
      automation.save(this.form.getProperties("name", "script")).then(() => {
        this._resetForm();
        this.transitionToRoute("adminPlugins.discourse-automation.edit", automation.id);
      }).catch(e => {
        this.set("error", (0, _ajaxError.extractError)(e));
      });
    }
    _resetForm() {
      this.set("form", _object.default.create({
        name: null,
        script: null
      }));
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "saveAutomation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveAutomation"), _class.prototype)), _class);
});
define("discourse/plugins/discourse-automation/discourse/components/fields/da-period-field", ["exports", "I18n", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "select-kit/components/combo-box", "@ember/component", "@ember/object", "@ember/helper", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "@ember/modifier", "@ember-compat/tracked-built-ins", "@ember/template-factory"], function (_exports, _I18n, _daBaseField, _comboBox, _component, _object, _helper, _daFieldLabel, _daFieldDescription, _modifier, _trackedBuiltIns, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let PeriodField = _exports.default = (_class = (_class2 = class PeriodField extends _daBaseField.default {
    constructor() {
      super(...arguments);
      if (!this.args.field.metadata.value) {
        this.args.field.metadata.value = new _trackedBuiltIns.TrackedObject({
          interval: 1,
          frequency: null
        });
      }
    }
    get recurringLabel() {
      return _I18n.default.t("discourse_automation.triggerables.recurring.every");
    }
    get replacedContent() {
      return (this.args.field?.extra?.content || []).map(r1 => {
        return {
          id: r1.id,
          name: _I18n.default.t(r1.name)
        };
      });
    }
    mutInterval(event1) {
      this.args.field.metadata.value.interval = event1.target.value;
    }
    mutFrequency(value1) {
      this.args.field.metadata.value.frequency = value1;
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="field period-field control-group">
        <DAFieldLabel @label={{@label}} @field={{@field}} />
  
        <div class="controls">
          {{this.recurringLabel}}
  
          <Input
            @type="number"
            defaultValue="1"
            @value={{@field.metadata.value.interval}}
            disabled={{@field.isDisabled}}
            required={{@field.isRequired}}
            {{on "input" this.mutInterval}}
          />
  
          <ComboBox
            @value={{@field.metadata.value.frequency}}
            @content={{this.replacedContent}}
            @onChange={{this.mutFrequency}}
            @options={{hash allowAny=false disabled=@field.isDisabled}}
            @required={{@field.isRequired}}
          />
  
          <DAFieldDescription @description={{@description}} />
        </div>
      </div>
    
  */
  {
    "id": "6I6/kc8B",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"field period-field control-group\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[1,[30,0,[\"recurringLabel\"]]],[1,\"\\n\\n        \"],[8,[32,1],[[24,\"defaultValue\",\"1\"],[16,\"disabled\",[30,2,[\"isDisabled\"]]],[16,\"required\",[30,2,[\"isRequired\"]]],[4,[32,2],[\"input\",[30,0,[\"mutInterval\"]]],null]],[[\"@type\",\"@value\"],[\"number\",[30,2,[\"metadata\",\"value\",\"interval\"]]]],null],[1,\"\\n\\n        \"],[8,[32,3],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\",\"@required\"],[[30,2,[\"metadata\",\"value\",\"frequency\"]],[30,0,[\"replacedContent\"]],[30,0,[\"mutFrequency\"]],[28,[32,4],null,[[\"allowAny\",\"disabled\"],[false,[30,2,[\"isDisabled\"]]]]],[30,2,[\"isRequired\"]]]],null],[1,\"\\n\\n        \"],[8,[32,5],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-period-field.js",
    "scope": () => [_daFieldLabel.default, _component.Input, _modifier.on, _comboBox.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _class2), _class2), (_applyDecoratedDescriptor(_class.prototype, "mutInterval", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mutInterval"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mutFrequency", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mutFrequency"), _class.prototype)), _class);
});
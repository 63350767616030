define("discourse/plugins/discourse-automation/discourse/components/fields/da-post-field", ["exports", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "discourse/plugins/discourse-automation/discourse/components/placeholders-list", "discourse/components/d-editor", "@ember/template-factory", "@ember/component"], function (_exports, _daBaseField, _daFieldLabel, _daFieldDescription, _placeholdersList, _dEditor, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class PostField extends _daBaseField.default {}
  _exports.default = PostField;
  _class = PostField;
  (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field post-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <div class="field-wrapper">
              <DEditor @value={{@field.metadata.value}} />
  
              <DAFieldDescription @description={{@description}} />
  
              {{#if this.displayPlaceholders}}
                <PlaceholdersList
                  @currentValue={{@field.metadata.value}}
                  @placeholders={{@placeholders}}
                  @onCopy={{this.mutValue}}
                />
              {{/if}}
            </div>
          </div>
        </div>
      </section>
    
  */
  {
    "id": "RT1VWl60",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field post-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"field-wrapper\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@value\"],[[30,2,[\"metadata\",\"value\"]]]],null],[1,\"\\n\\n            \"],[8,[32,2],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"displayPlaceholders\"]],[[[1,\"              \"],[8,[32,3],null,[[\"@currentValue\",\"@placeholders\",\"@onCopy\"],[[30,2,[\"metadata\",\"value\"]],[30,4],[30,0,[\"mutValue\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\",\"@placeholders\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-post-field.js",
    "scope": () => [_daFieldLabel.default, _dEditor.default, _daFieldDescription.default, _placeholdersList.default],
    "isStrictMode": true
  }), _class);
});
define("discourse/plugins/discourse-automation/discourse/components/fields/da-custom-field", ["exports", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "@ember/object", "discourse-common/utils/decorators", "@ember/render-modifiers/modifiers/did-insert", "@glimmer/tracking", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "select-kit/components/combo-box", "@ember/helper", "@ember/service", "@ember/template-factory", "@ember/component"], function (_exports, _daBaseField, _object, _decorators, _didInsert, _tracking, _daFieldLabel, _daFieldDescription, _comboBox, _helper, _service, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let GroupField = _exports.default = (_class = (_class2 = class GroupField extends _daBaseField.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "allCustomFields", _descriptor2, this);
    }
    loadUserFields() {
      this.store.findAll("user-field").then(fields1 => {
        this.allCustomFields = fields1.content;
      });
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field group-field" {{didInsert this.loadUserFields}}>
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <ComboBox
              @content={{this.allCustomFields}}
              @value={{@field.metadata.value}}
              @onChange={{this.mutValue}}
            />
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "2jyXNqyb",
    "block": "[[[1,\"\\n    \"],[11,\"section\"],[24,0,\"field group-field\"],[4,[32,0],[[30,0,[\"loadUserFields\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,2],null,[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"allCustomFields\"]],[30,2,[\"metadata\",\"value\"]],[30,0,[\"mutValue\"]]]],null],[1,\"\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-custom-field.js",
    "scope": () => [_didInsert.default, _daFieldLabel.default, _comboBox.default, _daFieldDescription.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "allCustomFields", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadUserFields", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "loadUserFields"), _class.prototype)), _class);
});
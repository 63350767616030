define("discourse/plugins/discourse-automation/discourse/components/fields/da-date-time-field", ["exports", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "@ember/object", "@ember/component", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "discourse/components/d-button", "@ember/modifier", "@ember/template-factory"], function (_exports, _daBaseField, _object, _component, _daFieldLabel, _daFieldDescription, _dButton, _modifier, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let DateTimeField = _exports.default = (_class = (_class2 = class DateTimeField extends _daBaseField.default {
    convertToUniversalTime(event1) {
      const date1 = event1.target.value;
      if (!date1) {
        return;
      }
      this.mutValue(moment(date1).utc().format());
    }
    reset() {
      this.mutValue(null);
    }
    get localTime() {
      return this.args.field.metadata.value && moment(this.args.field.metadata.value).local().format(moment.HTML5_FMT.DATETIME_LOCAL);
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field date-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <div class="controls-row">
              <Input
                @type="datetime-local"
                @value={{readonly this.localTime}}
                disabled={{@field.isDisabled}}
                {{on "input" this.convertToUniversalTime}}
              />
  
              {{#if @field.metadata.value}}
                <DButton
                  @icon="trash-alt"
                  @action={{this.reset}}
                  @disabled={{@field.isDisabled}}
                />
              {{/if}}
            </div>
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "jk5t9SjS",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field date-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"controls-row\"],[12],[1,\"\\n            \"],[8,[32,1],[[16,\"disabled\",[30,2,[\"isDisabled\"]]],[4,[32,2],[\"input\",[30,0,[\"convertToUniversalTime\"]]],null]],[[\"@type\",\"@value\"],[\"datetime-local\",[28,[31,0],[[30,0,[\"localTime\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,2,[\"metadata\",\"value\"]],[[[1,\"              \"],[8,[32,3],null,[[\"@icon\",\"@action\",\"@disabled\"],[\"trash-alt\",[30,0,[\"reset\"]],[30,2,[\"isDisabled\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n\\n          \"],[8,[32,4],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[\"readonly\",\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-date-time-field.js",
    "scope": () => [_daFieldLabel.default, _component.Input, _modifier.on, _dButton.default, _daFieldDescription.default],
    "isStrictMode": true
  }), _class2), _class2), (_applyDecoratedDescriptor(_class.prototype, "convertToUniversalTime", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "convertToUniversalTime"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reset"), _class.prototype)), _class);
});
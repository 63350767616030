define("discourse/plugins/discourse-automation/discourse/components/fields/da-text-list-field", ["exports", "discourse/plugins/discourse-automation/discourse/components/fields/da-base-field", "select-kit/components/multi-select", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-label", "discourse/plugins/discourse-automation/discourse/components/fields/da-field-description", "@ember/helper", "@ember/template-factory", "@ember/component"], function (_exports, _daBaseField, _multiSelect, _daFieldLabel, _daFieldDescription, _helper, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class TextListField extends _daBaseField.default {}
  _exports.default = TextListField;
  _class = TextListField;
  (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field text-list-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <MultiSelect
              @value={{@field.metadata.value}}
              @content={{@field.metadata.value}}
              @onChange={{this.mutValue}}
              @nameProperty={{null}}
              @valueProperty={{null}}
              @options={{hash allowAny=true disabled=@field.isDisabled}}
            />
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "nFgzywS3",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field text-list-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@value\",\"@content\",\"@onChange\",\"@nameProperty\",\"@valueProperty\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,2,[\"metadata\",\"value\"]],[30,0,[\"mutValue\"]],null,null,[28,[32,2],null,[[\"allowAny\",\"disabled\"],[true,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-automation/discourse/components/fields/da-text-list-field.js",
    "scope": () => [_daFieldLabel.default, _multiSelect.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _class);
});